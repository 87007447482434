import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

function Footer() {
  return (
    // <footer>
    //   <div className="flex flex-col items-center justify-center">
    //     <div className="flex items-center justify-center">Icons</div>
    //     <copyright>Bartosz Jozefowicz &copy; 2024</copyright>
    //   </div>
    // </footer>
    <footer className="footer items-center p-4">
      <aside className="items-center grid-flow-col">
        <p className="uppercase opacity-50">Bartosz Jozefowicz © 2024</p>
      </aside>
      <nav className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
        <a
          href="https://www.facebook.com/bartek.jozefowicz"
          className="text-2xl text-base-100 about-feature bg-primary p-4 hover:scale-105 transition-all"
        >
          <FaFacebook />
        </a>
        <a
          className="text-2xl text-base-100 about-feature bg-primary p-4 hover:scale-105 transition-all"
          href="https://www.instagram.com/_launam00r/"
        >
          <FaInstagram />
        </a>
        <a
          className="text-2xl text-base-100 about-feature bg-primary p-4 hover:scale-105 transition-all"
          href="https://github.com/launamoor"
        >
          <FaGithub />
        </a>
      </nav>
    </footer>
  );
}

export default Footer;
