import React from "react";
import about from "../assets/about.jpg";
import Featured from "./Featured";
import { MdDevices } from "react-icons/md";
import { MdOutlineLightbulb } from "react-icons/md";
import { MdRocketLaunch } from "react-icons/md";

const AboutSection = () => {
  return (
    <section id="about" className="py-24">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-5xl font-bold heading-underline mb-24">About</h2>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-16 mb-24">
          <div className="flex flex-col items-start gap-4 text-lg">
            <p>
              I am an aspiring front-end developer and software development
              student at Open University, Milton Keynes — UK, who has been
              coding for over two years. Starting out with Python, then
              JavaScript and React, I've spent countless hours polishing my
              skills and developing new techniques. It didn't take long before
              programming was all I could think about. My dreams are limitless
              when it comes to what potential can be tapped through this
              profession, every accomplishment brings me one step closer towards
              being confident enough to call myself a front-end developer.
            </p>
            <p>
              I'm a detail oriented problem solver who also enjoys playing
              guitar.
            </p>
            <p>
              I am passionate about coding, solving problems through the code,
              UI/UX Design and music.
            </p>
          </div>
          <img
            src={about}
            alt="Bartosz Jozefowicz"
            className="w-96 about-img"
          />
        </div>
        <div className="grid md:grid-cols-3 md:gap-0 gap-8">
          <Featured
            icon={<MdDevices className="text-6xl text-base-100" />}
            title="Responsive"
            description="My layouts are compatible with all devices, big or small."
          />
          <Featured
            icon={<MdRocketLaunch className="text-6xl text-base-100" />}
            title="Dynamic"
            description="I enjoy giving pages life, they don't have to be static."
          />
          <Featured
            icon={<MdOutlineLightbulb className="text-6xl text-base-100" />}
            title="Intuitive"
            description="I like user-friendly, intuitive UI/UX."
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
