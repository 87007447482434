import React from "react";
import { RxCross2 } from "react-icons/rx";

function Modal({ modalData, onClick, onClose }) {
  return (
    <div
      onClick={onClick}
      id="overlay"
      className="fixed top-0 left-0 overlay-drop z-40 overflow-hidden flex items-center justify-center"
    >
      <div className="modalButtons">
        <a
          href={modalData.link.live}
          target="_blank"
          rel="noreferrer"
          className="btn md:btn-md btn-sm btn-primary rounded-none btn-left"
        >
          VISIT
        </a>
        {modalData.link.repo && (
          <a
            href={modalData.link.repo}
            target="_blank"
            rel="noreferrer"
            className="btn md:btn-md btn-sm btn-primary rounded-none btn-right"
          >
            REPO
          </a>
        )}
      </div>
      <div className="modalbox text-base-100 text-3xl shadow-md bg-secondary-content relative z-50">
        <button
          onClick={onClose}
          className="fixed top-8 right-10 sm:hidden block"
        >
          <RxCross2 className="text-primary h-8 w-8 z-50" />
        </button>
        <div className="flex flex-col items-stretch overflow-scroll">
          <img src={modalData.img} alt={modalData.title} />
          <div className="min-h-36 overflow-hidden relative">
            <div className="p-4 flex flex-col gap-2 text-black">
              <h2 className="text-xl font-bold">{modalData.title}</h2>
              <p className="lg:text-sm text-xs pb-20">
                {modalData.description}
              </p>
              <div className="flex items-center justify-between absolute bottom-0 right-0 w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
