import React from "react";
// import CanvasAnimation from "../UI/CanvasAnimation";
// import Starfield from "../UI/Starfield";
import ParticlesBackground from "../UI/ParticlesBackground";

function Header() {
  return (
    <header className="overflow-x-hidden">
      <div className="fullscreen flex items-center justify-center bg-primary header-gradient">
        {/* <CanvasAnimation /> */}
        {/* <Starfield /> */}

        <div
          style={{ position: "relative", zIndex: 2 }}
          className="flex flex-col items-center justify-center gap-4 text-center"
        >
          <h1 className="text-3xl sm:text-4xl text-base-100 font-bold select-none">
            Hi, I'm <span className="text-secondary">Bart</span>.
          </h1>
          <h2 className="text-2xl sm:text-3xl text-base-100 font-semibold select-none">
            I am an aspiring front-end developer
          </h2>
        </div>
      </div>
      <div className="w-full h-screen absolute top-0 left-0">
        <div className="w-full h-screen">
          <ParticlesBackground />
        </div>
      </div>
    </header>
  );
}

export default Header;
