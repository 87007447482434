import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Skill({ name, percentage, icon }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    if (inView) setViewed(true);
  }, [viewed, inView]);
  return (
    <div ref={ref} className="flex items-center justify-between gap-4">
      <div className="w-60 flex items-center justify-start gap-8">
        <div className={`h-8 ${name === "Next.js" ? "w-12 pt-1" : "w-8"}`}>
          <img src={icon} alt={`${name} icon`} />
        </div>
        <div className={`${name === "Next.js" ? "-ml-4" : ""}`}>{name}</div>
      </div>
      <div className="h-6 overflow-hidden w-full border-solid border-accent relative">
        <div
          style={{ width: viewed ? percentage : "0" }}
          className="h-full bg-gradient-to-r from-accent to-warning text-center transition-all transition-2s"
        >
          <div className="absolute top-1/2 left-8 -translate-x-1/2 -translate-y-1/2 text-white font-light"></div>
        </div>
      </div>
    </div>
  );
}

export default Skill;
