import React, { useEffect, useState, useMemo } from "react";
import Particles from "@tsparticles/react";
import { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () => ({
      fpsLimit: 120, // Frames per second limit
      fullScreen: {
        enable: false,
        zIndex: 0,
      },
      interactivity: {
        events: {
          onClick: {
            enable: false, // Change to false and rebuild
            mode: "push", // Adds particles on click
          },
          onHover: {
            enable: true,
            mode: "repulse", // Repulses particles on hover
          },
          resize: true, // Enables responsive design on resize
        },
        modes: {
          push: {
            quantity: 4, // Number of particles added on click
          },
          repulse: {
            distance: 300, // Distance of repulsion on hover
            duration: 1, // Duration of repulsion effect
            speed: 1,
            maxSpeed: 50,
            easing: "ease-out-quad",
          },
        },
      },
      detectOn: "canvas",
      particles: {
        color: {
          value: "#fff", // Color of particles
        },
        links: {
          color: "#ffffff", // Color of the links between particles
          distance: 200, // Maximum distance for linking particles
          enable: true, // Enables linking particles
          opacity: 0.3, // Opacity of the links
          width: 1, // Width of the links
        },
        move: {
          direction: "none", // Direction of particle movement
          enable: true, // Enables particle movement
          outModes: {
            default: "bounce", // Behavior when particles reach the edge
          },
          random: false, // Randomness of movement
          speed: { min: 1, max: 5 }, // Speed of particle movement
          straight: false, // Straightness of particle movement
        },
        number: {
          density: {
            enable: true, // Enables a density area for the particles
            area: 800, // Density area
          },
          value: 80, // Number of particles
        },
        opacity: {
          value: { min: 0.3, max: 0.7 }, // Opacity of particles
        },
        shape: {
          type: "polygon", // Shape of particles
        },
        size: {
          value: { min: 1, max: 5 }, // Size range of particles
        },
      },
      detectRetina: true, // Enables retina display detection
    }),
    []
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        className="w-full h-screen"
        options={options}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  return null;
};

export default ParticlesBackground;
