import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Project({ image, name, tech, onClick, className }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    if (inView) setViewed(true);
  }, [viewed, inView]);
  return (
    <div
      style={{ visibility: viewed ? "visible" : "hidden" }}
      ref={ref}
      className={`image-container ${viewed && className}`}
    >
      <img src={image} alt={name} />
      <div className="overlay">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl">{name}</h3>
          <p className="text-secondary">{tech}</p>
        </div>
        <button
          onClick={onClick}
          className="text-nowrap project-button transition-all text-lg"
        >
          Learn more
        </button>
      </div>
    </div>
  );
}

export default Project;
