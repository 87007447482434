import React, { useState } from "react";
import Project from "./Project";
import fusion from "../assets/fusion.jpg";
import market from "../assets/market.jpg";
import bejger from "../assets/bejger.jpg";
import animal from "../assets/animal.jpg";
import natours from "../assets/natours.jpg";
import audiophile from "../assets/audiophile.png";
import Modal from "./Modal";
import fusionmodal from "../assets/fusion-modal.jpg";
import natoursmodal from "../assets/natours-modal.jpg";
import animmodal from "../assets/bezdomniaki-modal.jpg";
import marketmodal from "../assets/housemarketplace-modal.jpg";
import bejgmodal from "../assets/bejgermanufaktura-modal.jpg";
import audiomodal from "../assets/audiophile-modal.jpg";

const PortfolioSection = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const projectData = {
    fusionOnline: {
      img: fusionmodal,
      title: "Fusion Online",
      description:
        "Fusion Online is a React-based web application that integrates with the Radio-Browser API to search and filter radio stations according to user input. The application boasts a fully responsive design, achieved through TailwindCSS and DaisyUI, enhancing user experience on various devices.",
      link: {
        live: "https://fusion-online.netlify.app/",
        repo: "https://github.com/launamoor/fusion-radio-browser",
      },
    },
    marketplace: {
      img: marketmodal,
      title: "House Marketplace",
      description:
        "House Marketplace is a React-based application utilizing Firebase for backend services, including data storage and user authentication. It offers features like user sign-up/sign-in, browsing property listings, and options to list houses for sale or rent. The integration of Google OAuth enables users to conveniently sign in using their Google accounts. Designed with a mobile-first approach, it ensures a seamless user experience on smartphones and other devices.",
      link: {
        live: "https://housemarket-fb.netlify.app/",
        repo: "https://github.com/launamoor/house-marketplace",
      },
    },
    natoursproject: {
      img: natoursmodal,
      title: "Natours Project",
      description:
        "Natours Project is a web development initiative showcasing the capabilities and aesthetics of SaSS (Syntactically Awesome Style Sheets) through a project built for a fictional company. It leverages HTML and SaSS to create a visually appealing and fully responsive website design, demonstrating the practical applications and benefits of using SaSS in modern web development.",
      link: {
        live: "https://natours-advsass.netlify.app/",
      },
    },
    bejgermanufaktura: {
      img: bejgmodal,
      title: "Bespoke Joinery Website",
      description:
        "Bespoke Joinery Website is a custom-designed web platform built for a Polish carpentry business, utilizing React and CSS. It features React Router for seamless navigation across multiple gallery views, enhancing user engagement. The site employs React Intersection Observer to trigger animations for components as they enter the viewport, adding a dynamic visual appeal. The design is fully responsive, ensuring optimal viewing and interaction across various devices.",
      link: {
        live: "https://bejgermanufaktura.pl/",
      },
    },
    animalwelfare: {
      img: animmodal,
      title: "Animal Welfare Association Website",
      description:
        "Animal Welfare Association is a React-based web application designed for managing pet adoptions and related events. It uses Firebase for backend operations, including data storage and user authentication. The app features React Router for navigating between different views and routes. Users can log in using their email and password, add or remove pet adoption listings with details like names, descriptions, and images, and post new events. These additions are dynamically loaded from the database at application start. TailwindCSS and DaisyUI are used to ensure a fully responsive and user-friendly design.",
      link: {
        live: "https://bezdomniaki.netlify.app/",
      },
    },
    audiophile: {
      img: audiomodal,
      title: "Audiophile E-Commerce",
      description:
        "This large e-commerce project was part of the Frontend Mentor challenge. The project was build with Next.js 14, and included professional workflow with Figma design and assets provided",
      link: {
        live: "https://next-audiophile.netlify.app/",
        repo: "https://github.com/launamoor/audiophile",
      },
    },
  };

  const handleClick = (data) => {
    setModalOpen(true);
    setModalData(data);
  };

  const handleCloseModal = (e) => {
    if (e.target.id === "overlay") setModalOpen(false);
  };

  const onClose = () => {
    setModalOpen(false);
  };
  return (
    <section id="portfolio" className="py-24">
      {modalOpen ? (
        <Modal
          onClose={onClose}
          onClick={handleCloseModal}
          modalData={modalData}
        />
      ) : (
        ""
      )}
      <div className="flex flex-col items-center justify-center gap-4">
        <h2 className="text-5xl font-bold heading-underline mb-24">
          Portfolio
        </h2>
        <div className="preload">
          <img
            src={projectData.fusionOnline.img}
            alt={projectData.fusionOnline.title}
          />
          <img
            src={projectData.animalwelfare.img}
            alt={projectData.animalwelfare.title}
          />
          <img
            src={projectData.natoursproject.img}
            alt={projectData.natoursproject.title}
          />
          <img
            src={projectData.marketplace.img}
            alt={projectData.marketplace.title}
          />
          <img
            src={projectData.bejgermanufaktura.img}
            alt={projectData.bejgermanufaktura.title}
          />
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 lg:gap-0 gap-8 grid-rows-2">
          <Project
            onClick={() => handleClick(projectData.audiophile)}
            name="Audiophile E-Commerce"
            image={audiophile}
            tech="Next.js 14"
            className={"animation-1"}
          />
          <Project
            onClick={() => handleClick(projectData.natoursproject)}
            name="Natours Project"
            image={natours}
            tech="HTML / SASS"
            className={"animation-2"}
          />
          <Project
            onClick={() => handleClick(projectData.fusionOnline)}
            name="Fusion Online"
            image={fusion}
            tech="React"
            className={"animation-3"}
          />
          <Project
            onClick={() => handleClick(projectData.animalwelfare)}
            name="Animal Welfare Association Website"
            image={animal}
            tech="React / Firebase"
            className={"animation-4"}
          />
          <Project
            onClick={() => handleClick(projectData.marketplace)}
            name="House Marketplace"
            image={market}
            tech="React / Firebase"
            className={"animation-5"}
          />
          <Project
            onClick={() => handleClick(projectData.bejgermanufaktura)}
            name="Bespoke Joinery Website"
            image={bejger}
            tech="React"
            className={"animation-6"}
          />
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
