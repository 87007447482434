import React from "react";
import Navigation from "../components/Navigation";
import AboutSection from "../components/AboutSection";
import SkillsSection from "../components/SkillsSection";
import PortfolioSection from "../components/PortfolioSection";
import ContactSection from "../components/ContactSection";

function Main() {
  return (
    <main>
      <Navigation />
      <div className="container mx-auto px-2">
        <AboutSection />
      </div>
      <SkillsSection />
      <div className="container mx-auto px-2">
        <PortfolioSection />
      </div>
      <ContactSection />
    </main>
  );
}

export default Main;
