import React from "react";
import Skill from "./Skill";
import html from "../assets/html.png";
import css from "../assets/css.png";
import js from "../assets/js.png";
import react from "../assets/react.png";
import next from "../assets/nextjs.png";
import java from "../assets/java.png";

const SkillsSection = () => {
  return (
    <section id="skills" className="bg-secondary-content py-24">
      <div className="container mx-auto px-2">
        <div className="flex flex-col items-center justify-center gap-4">
          <h2 className="text-5xl font-bold heading-underline-accent mb-24 relative">
            Skills
          </h2>
          <div
            className={`grid grid-cols-1 grid-rows-6 gap-y-6 transition-all justify-items-between w-full max-w-6xl`}
          >
            <Skill icon={html} name="HTML" percentage={"78%"} />
            <Skill icon={css} name="CSS" percentage={"78%"} />
            <Skill icon={js} name="JavaScript" percentage={"78%"} />
            <Skill icon={react} name="React" percentage={"73%"} />
            <Skill icon={next} name="Next.js" percentage={"73%"} />
            <Skill icon={react} name="React-Native" percentage={"55%"} />
            <Skill icon={java} name="Java" percentage={"50%"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
