import React from "react";

function Featured({ title, description, icon }) {
  return (
    <div className="flex flex-col items-center gap-2">
      <div className="bg-accent p-12 md:p-8 lg:p-12 about-feature">{icon}</div>
      <p className="text-3xl md:text-2xl lg:text-3xl font-bold">{title}</p>
      <p className="text-md lg:text-lg w-3/5 text-center leading-tight">
        {description}
      </p>
    </div>
  );
}

export default Featured;
