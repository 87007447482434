import React from "react";
import logo from "../assets/logo-250x250.png";
import { FaAngleDoubleUp } from "react-icons/fa";
import { useInView } from "react-intersection-observer";

function Navigation() {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNav = (el) => {
    const element = document.getElementById(el);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div ref={ref}></div>
      <nav
        className={`${
          inView ? "" : "sticky top-0 left-0 z-10 opacity-80 transition-all"
        }`}
      >
        <div className="navbar bg-base-100 px-4 py-0">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <button
                    onClick={() => handleNav("about")}
                    className="text-lg hover:bg-base-100 hover:text-secondary"
                  >
                    About
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNav("skills")}
                    className="text-lg hover:bg-base-100 hover:text-secondary"
                  >
                    Skills
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNav("portfolio")}
                    className="text-lg hover:bg-base-100 hover:text-secondary"
                  >
                    Portfolio
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNav("contact")}
                    className="text-lg hover:bg-base-100 hover:text-secondary"
                  >
                    Contact
                  </button>
                </li>
              </ul>
            </div>
            <div className="avatar">
              <div className="w-12 sm:w-24 rounded sm:p-0 p-1">
                <img src={logo} alt="BJ Logo" />
              </div>
            </div>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <button
                  onClick={() => handleNav("about")}
                  className="text-lg hover:bg-base-100 hover:text-secondary"
                >
                  About
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNav("skills")}
                  className="text-lg hover:bg-base-100 hover:text-secondary"
                >
                  Skills
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNav("portfolio")}
                  className="text-lg hover:bg-base-100 hover:text-secondary"
                >
                  Portfolio
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNav("contact")}
                  className="text-lg hover:bg-base-100 hover:text-secondary"
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
          <div className="navbar-end">
            <button
              onClick={handleClick}
              className="btn sm:text-xl text-lg text-primary bg-base-100 btn-circle sm:btn-md btn-sm"
            >
              <FaAngleDoubleUp />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
