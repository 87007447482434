import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactSection = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_39ul3za",
        "template_y53xmii",
        formRef.current,
        "XF_E3k-V5qG8-lS9M"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          toast.success("Email sent!");
        },
        (error) => {
          console.log(error.text);
          toast.error("Something went wrong");
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section
      id="contact"
      className="pb-24 pt-48 contact-polygon bg-primary text-base-100"
    >
      <ToastContainer position="top-right" autoClose={2000} />
      <div className="px-2 container mx-auto">
        <div className="flex flex-col items-center justify-center gap-8">
          <h2 className="text-5xl font-bold heading-underline-base mb-4">
            Contact
          </h2>
          <p className="text-lg lg:w-1/2 w-full text-center">
            I am seeking out opportunities to collaborate with companies or
            individuals. I want to work together on projects that are both
            beautiful and functional, develop my skills, experience and
            knowledge and take them as far as they'll go!
          </p>
          <p className="text-lg lg:w-1/2 w-full text-center">
            If that all sound good, let's talk about how we can combine our
            efforts.
          </p>
        </div>
        <div className="my-12 flex flex-col gap-4">
          <form
            ref={formRef}
            onSubmit={sendEmail}
            className="form-control gap-1"
          >
            <label className="label form-control p-0 relative">
              <span className="absolute top-1/2 left-1/2 invisible">Name</span>
              <input
                required
                className="input text-black rounded-none w-full max-w-md bg-secondary-content"
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            <label className="label form-control p-0 relative">
              <span className="absolute top-1/2 left-1/2 invisible">Email</span>
              <input
                required
                className="input text-black rounded-none w-full max-w-md bg-secondary-content"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>

            <label className="label form-control p-0 relative">
              <span className="absolute top-1/2 left-1/2 invisible">
                Message
              </span>
              <textarea
                required
                className="text-black textarea textarea-bordered textarea-lg rounded-none w-full max-w-md border-0 bg-secondary-content min-h-36"
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              />
            </label>
            <button
              type="submit"
              className="btn rounded-none self-center px-12 uppercase mt-1"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
